.loading-dot {
  width: var(--size, 8px);
  height: var(--size, 8px);
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
  background-color: var(--glow-color, rgb(155, 46, 199));
  box-shadow: 0 0 20px var(--glow-color, rgb(155, 46, 199));
  animation: loading 1.5s ease-in-out infinite;
  animation-delay: var(--animation-delay, 0s);
}

@keyframes loading {
  0%,
  100% {
    transform: scale(0.2);
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.886);
  }
  42% {
    box-shadow: 0 0 10px rgba(204, 6, 118, 0.886);
  }
  50% {
    transform: scale(1);
    box-shadow:
      0 0 20px var(--glow-color, rgb(155, 46, 199)),
      0 0 30px var(--glow-color, rgb(155, 46, 199)),
      0 0 40px var(--glow-color, rgb(155, 46, 199));
  }
}
